import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Gmap } from "./gmap"

export const priceQuery = graphql`
  query PriceQuery {
    Pensions: allContentfulPrice(filter: {categories: {elemMatch: {title: {eq: "Pensions"}}}}) {
      edges {
        node {
          id
          title
          categories {
            id
            title
          }
          items {
            id
            title
            cost
            category
          }
        }
      }
    }

    Loisirs: allContentfulPrice(filter: {categories: {elemMatch: {title: {eq: "Loisirs"}}}}) {
      edges {
        node {
          id
          title
          categories {
            id
            title
          }
          items {
            id
            title
            cost
            category
          }
        }
      }
    }
  }
`;

export default function Price() {
  return (
    <StaticQuery
      query={priceQuery}
      render={data => {
        const loisirsNodes = data.Pensions.edges
        const pensionsNodes = data.Loisirs.edges

        const loisirs = loisirsNodes.map(nodes => {
          const data = nodes.node
          return (
            <div key={data.id} className="w-full p-6 px-4 m-4 mx-auto text-sm border border-gray-200 table-auto md:text-base">
              <table className="w-full table-auto">
                <thead>
                  <tr>
                    <th>{data.title}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.items.map(item => {
                    return (
                      <tr key={item.id} className="">
                        <td className="w-4/5">{item.title}</td>
                        <td className="w-1/5 text-right">{item.cost}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
            </div>
          )
        })

        const pensions = pensionsNodes.map(nodes => {
          const data = nodes.node
          return (
            <div key={data.id} className="w-full p-6 px-4 m-4 mx-auto text-sm border border-gray-200 table-auto md:text-base">
              <table className="w-full table-auto">
                <thead>
                  <tr>
                    <th>{data.title}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.items.map(item => {
                    return (
                      <tr key={item.id} className="">
                        {/* <p>{RenderRichText(item.additionalInfo)}</p> */}
                        <td className="w-4/5">
                          {item.title} <span className="text-sm italic font-semibold text-green-500">{item.category ? `(${item.category})` : ''}</span>
                        </td>
                        <td className="w-1/5 text-right">{item.cost}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
            </div>
          )
        })

        return (
          <div>
            <section className="px-4 mt-6 text-justify md:mx-auto md:max-w-7xl">
              {loisirs}
              {pensions}
            </section>
            <Gmap />
          </div>
        )
      }}
    />
  )
}

